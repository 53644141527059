export enum Dateformat {
  short = 'dd/MM/yyyy',
  detailed = 'dd/MM/yyyy H:mm:ss',
  detailedMonth = 'dd MMMM yyyy',
  time = 'H:mm',
  transitAPI ="yyyy-MM-dd'T'HH:mm:ss.SSSX"
}

export enum BookingResultAction {
  PICK_OTHER_SPOT,
  GO_TO_SHOP,
  DISMISS,
  GO_TO_DETAILS
}

export enum PaymentResultAction {
  PICK_OTHER_METHOD,
  GO_TO_SHOP,
  GO_TO_ORDER_STATUS
}

export enum OnlinePaymentStatus {
  PAYMENT_SUCCEED,
  PAYMENT_FAILED,
  PAYMENT_PENDING
}

export enum OrderResultAction {
  NAVIGATE_TO_SHOP,
  GO_HOME,
  GO_TO_ORDER_STATUS
}

export enum BookingStatus {
  all = 'all',
  booked = 'booked',
  seated = 'seated',
  completed = 'completed',
  canceled = 'canceled',
  submitted = 'submitted'
}

///  Booking Transit    vvvvvvvvvvvvvvvv
export enum JourneyStatus {
  all = 'all',
  booked = 'booked',
  completed = 'completed',
  canceled = 'canceled',
  submitted = 'submitted',
  rejected= 'rejected'
}
export enum Availabilities_Transit_Status{
  initial = 'initial',                              // Journey has offered prices from Transit business
  requested='requested',                            // Journey has selected transits
  request_accepted='request_accepted',
  request_rejected ='request_rejected',
  user_accepted ='user_accepted',
  user_confirmed  ='user_confirmed',
  payment_rejected  ='payment_rejected',
  payment_accepted   ='payment_accepted',
  bussines_rejected  ='bussines_rejected',
  user_rejected    ='user_rejected',
  // Transit Statuses...
  accepted='accepted',
  rejected= 'rejected',
  canceled='canceled',
  assigned= 'assigned',
  completed= 'completed',
}

export enum BookingTransitType{
  oneway='one-way',
  roundtrip='roundtrip',
}

export enum BookingTripType{
  from='from',
  to= 'to',
}

export enum VehicleType{
  bicycle='bicycle',
  motorbike='motorbike',
  car='car',
  suv='suv',
  limo='limousine',
  minibus='minibus',
  speedboat='speedboat',
  helicopter='helicopter'
}

export enum BookingType{
  transit='transit',
  venue='venue',
  appointment= 'appointment'
}

///  Booking Transit ---------------------

export enum PaymentStatus {
    all = 'all',//
    booked = 'booked', //
    seated = 'seated', //
    completed = 'completed',//
    canceled = 'canceled',//
    disputed = 'submitted'
}



export enum OrderStatus {
  all = 'all',
  open = 'open',
  finalized = 'finalized',
  pending = 'pending',
  queued = 'queued',
  ready = 'ready',
  delayed = 'delayed',
  inprogress = 'inprogress',
  ontheway = 'ontheway',
  delivered = 'delivered',
  disputed = 'disputed',
  rejected = 'rejected',
  canceled = 'canceled',
  submitted = 'submitted'
}

export enum PaymentMethod {
  all = 'all',
  creditCard = 'creditCard',
  paypal = 'paypal'
}

export enum HistoryFilterType {
  ORDER_STATUS,
  BOOKING_STATUS,
  PAYMENT_METHOD
}
