import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocaleService } from '../locale/locale.service';
import { UserDataService } from '../user-data/user-data.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { ModalController } from '@ionic/angular';
import { NgHttpCachingHeaders, NgHttpCachingService } from 'ng-http-caching';
import { CategoryWithSuggestions } from "../../models/agent-category.interface";


@Injectable({
  providedIn: 'root'
})
export class SuggestionService {

  constructor(
    private http: HttpClient,
    private localeService: LocaleService,
    private userDataService: UserDataService,
    private logger: NGXLogger,
    private modalCtrl: ModalController,
    private ngHttpCachingService: NgHttpCachingService,
  ) {}

  getSuggestions(cafeID, categoryId, forceUpdate: boolean = false): Observable<CategoryWithSuggestions> {
    if (forceUpdate) {
      this.ngHttpCachingService.clearCacheByTag('SUGGESTIONS');
    }
    
    let headers = new HttpHeaders();
    headers = headers.append(NgHttpCachingHeaders.ALLOW_CACHE, '1');
    headers = headers.append(NgHttpCachingHeaders.TAG, 'SUGGESTIONS');
    
    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('shopId', cafeID);
    url_params = url_params.append("locale", this.localeService.getSelectedLanguage())
    return this.http.get<any>(environment.QpassConfiguration.apiUrl + "/suggestion/categories", {headers, params: url_params}).pipe(
      map(res => {
        return res.categories.find(item => item.id === +categoryId);
      }),
      catchError(this.handleError('providers.suggestion.getSuggestions'))
    );
  }

  getCategories(cafeID, forceUpdate: boolean = false): Observable<Array<CategoryWithSuggestions>> {
    if (forceUpdate) {
      this.ngHttpCachingService.clearCacheByTag('SUGGESTIONS');
    }

    let headers = new HttpHeaders();
    headers = headers.append(NgHttpCachingHeaders.ALLOW_CACHE, '1');
    headers = headers.append(NgHttpCachingHeaders.TAG, 'SUGGESTIONS');

    let url_params: HttpParams = new HttpParams();
    url_params = url_params.append('shopId', cafeID);
    url_params = url_params.append("locale", this.localeService.getSelectedLanguage())
    return this.http.get<any>(environment.QpassConfiguration.apiUrl + "/suggestion/categories", {headers, params: url_params}).pipe(
        map(res => {
          return res.categories;
        }),
        catchError(this.handleError('providers.suggestion.getSuggestions'))
    );
  }

  private handleError (operation = 'operation') {
    return (err: HttpErrorResponse) => {
      let errMsg: string = "USER_SRV.ERR";

      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.logger.log(`${operation} failed: ${err.error.message}`);
        errMsg = "USER_SRV.ERR_NO_WEB_ACCESS";
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        this.logger.log(`${operation} failed: Backend returned code ${err.status}, body was: ${err.error}`);
        errMsg = "USER_SRV.ERR_SERVER_COMM";
      }
      return throwError(errMsg);
    }
  }
}

