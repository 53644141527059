import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth/auth.guard';
import {SplashPage} from './pages/splash/splash.page';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'splash',
        pathMatch: 'full'
    },
    {
        path: 'about',
        loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule)
    },
    {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountPageModule)
    },
    {
        path: 'assistantWelcome',
        loadChildren: () =>
            import('./pages/not-found/not-found.module').then((m) => m.NotFoundPageModule)
    },
    {
        path: 'basket',
        loadChildren: () => import('./pages/basket/basket.module').then((m) => m.BasketPageModule)
    },
    {
        path: 'cafeDetails',
        loadChildren: () =>
            import('./pages/cafe-details/cafe-details.module').then((m) => m.CafeDetailsPageModule)
    },
    {
        path: 'cafeMenu',
        loadChildren: () =>
            import('./pages/cafe-menu/cafe-menu.module').then((m) => m.CafeMenuPageModule)
    },
    {
        path: 'cafeMenu/:id',
        loadChildren: () =>
            import('./pages/cafe-menu/cafe-menu.module').then((m) => m.CafeMenuPageModule)
    },
    {
        path: 'agentOffers',
        loadChildren: () =>
            import('./pages/agent-offers/agent-offers.module').then((m) => m.AgentOffersModule)
    },
    {
        path: 'agentOffers/:id',
        loadChildren: () =>
            import('./pages/agent-offers/agent-offers.module').then((m) => m.AgentOffersModule)
    },
    {
        path: 'agentCategory/:shop-id/:agent-category',
        loadChildren: () =>
            import('./pages/agent-category/agent-category.module').then((m) => m.AgentCategoryModule)
    },
    {
        path: 'cafeRating',
        loadChildren: () =>
            import('./pages/cafe-rating/cafe-rating.module').then((m) => m.CafeRatingPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'categories',
        loadChildren: () =>
            import('./pages/categories/categories.module').then((m) => m.CategoriesPageModule)
    },
    {
        path: 'coupons',
        loadChildren: () => import('./pages/coupons/coupons.module').then(m => m.CouponsPageModule)
    },
    {
        path: 'coupon-add',
        loadChildren: () => import('./pages/coupon-add/coupon-add.module').then(m => m.CouponAddPageModule)
    },
    {
        path: 'coupons/:id',
        loadChildren: () => import('./pages/coupon/coupon.module').then(m => m.CouponPageModule)
    },
    {
        path: 'deliveryOptions',
        loadChildren: () =>
            import('./pages/delivery-options/delivery-options.module').then(
                (m) => m.DeliveryOptionsPageModule
            )
    },
    {
        path: 'deliveryOptions/review',
        loadChildren: () =>
            import('./pages/delivery-options/delivery-options.module').then(
                (m) => m.DeliveryOptionsPageModule
            )
    },
    {
        path: 'favorites',
        loadChildren: () =>
            import('./pages/favorites/favorites.module').then((m) => m.FavoritesPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'history',
        loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule)
    },
    {
        path: 'language',
        loadChildren: () => import('./pages/language/language.module').then((m) => m.LanguagePageModule)
    },
    {
        path: 'menu',
        loadChildren: () => import('./pages/menu/menu.module').then((m) => m.MenuPageModule)
    },
    {
        path: 'messages',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule)
    },
    {
        path: 'messagesShop/:id',
        loadChildren: () => import('./pages/messages-shop/messages-shop.module').then(m => m.MessagesShopPageModule)
    },
    {
        path: 'messagesNewChat',
        loadChildren: () => import('./pages/messages-new-chat/messages-new-chat.module').then(m => m.MessagesNewChatPageModule)
    },
    {
        path: 'moneyDeposit',
        loadChildren: () =>
            import('./pages/money-deposit/money-deposit.module').then((m) => m.MoneyDepositPageModule)
    },
    {
        path: 'myOrders',
        loadChildren: () =>
            import('./pages/my-orders/my-orders.module').then((m) => m.MyOrdersPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'myOrdersDetails/:id',
        loadChildren: () =>
            import('./pages/my-orders-details/my-orders-details.module').then(
                (m) => m.MyOrdersDetailsPageModule
            )
    },
    {
        path: 'myPlacesEdit',
        loadChildren: () =>
            import('./pages/my-places-edit/my-places-edit.module').then((m) => m.MyPlacesEditPageModule)
    },
    {
        path: 'myPlacesInsertMapWeb',
        loadChildren: () =>
            import('./pages/my-places-insert-map-web/my-places-insert-map-web.module').then(
                (m) => m.MyPlacesInsertMapWebPageModule
            )
    },
    {
        path: 'myPlacesList',
        loadChildren: () =>
            import('./pages/my-places-list/my-places-list.module').then((m) => m.MyPlacesListPageModule)
    },
    {
        path: 'onboarding',
        loadChildren: () =>
            import('./pages/onboarding/onboarding.module').then((m) => m.OnboardingPageModule)
    },
    {
        path: 'onlinePayment',
        loadChildren: () => import('./pages/online-payment/online-payment.module').then(m => m.OnlinePaymentPageModule)
    },
    {
        path: 'onlinePaymentMethods',
        loadChildren: () => import('./pages/online-payment-methods/online-payment-methods.module').then(m => m.OnlinePaymentMethodsPageModule)
    },
    {
        path: 'personal-data-settings',
        loadChildren: () => import('./pages/personal-data-settings/personal-data-settings.module').then(m => m.PersonalDataSettingsPageModule)
    },
    {
        path: 'orderReview',
        loadChildren: () =>
            import('./pages/order-review/order-review.module').then((m) => m.OrderReviewPageModule)
    },
    {
        path: 'orderType',
        loadChildren: () =>
            import('./pages/order-type/order-type.module').then((m) => m.OrderTypePageModule)
    },
    {
        path: 'orderType/review',
        loadChildren: () =>
            import('./pages/order-type/order-type.module').then((m) => m.OrderTypePageModule)
    },
    {
        path: 'passwordChange',
        loadChildren: () =>
            import('./pages/password-change/password-change.module').then(
                (m) => m.PasswordChangePageModule
            )
    },
    {
        path: 'paymentOptions',
        loadChildren: () =>
            import('./pages/payment-options/payment-options.module').then(
                (m) => m.PaymentOptionsPageModule
            )
    },
    {
        path: 'paymentOptions/review',
        loadChildren: () =>
            import('./pages/payment-options/payment-options.module').then(
                (m) => m.PaymentOptionsPageModule
            )
    },
    {
        path: 'rateOrder',
        loadChildren: () =>
            import('./pages/rate-order/rate-order.module').then((m) => m.RateOrderPageModule)
    },
    {
        path: 'searchShops',
        loadChildren: () =>
            import('./pages/search-shops/search-shops.module').then((m) => m.SearchShopsPageModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule)
    },
    {
        path: 'shopLanding',
        loadChildren: () => import('./pages/shop-landing/shop-landing.module').then( m => m.ShopLandingPageModule)
    },
    {
        path: 'shopLanding/:id',
        loadChildren: () => import('./pages/shop-landing/shop-landing.module').then( m => m.ShopLandingPageModule)
    },
    {
        path: 'speedOrders',
        loadChildren: () =>
            import('./pages/speed-orders/speed-orders.module').then((m) => m.SpeedOrdersPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'splash',
        component: SplashPage
        // loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
    },
    {
        path: 'takeawayOptions',
        loadChildren: () =>
            import('./pages/takeaway-options/takeaway-options.module').then(
                (m) => m.TakeawayOptionsPageModule
            )
    },
    {
        path: 'takeawayOptions/review',
        loadChildren: () =>
            import('./pages/takeaway-options/takeaway-options.module').then(
                (m) => m.TakeawayOptionsPageModule
            )
    },
    {
        path: 'track-delivery',
        loadChildren: () => import('./pages/track-delivery/track-delivery.module').then((m) => m.TrackDeliveryPageModule)
    },
    {
        path: 'verifyDelivery',
        loadChildren: () =>
            import('./pages/verify-delivery/verify-delivery.module').then(
                (m) => m.VerifyDeliveryPageModule
            )
    },
    {
        path: 'booking/:shopId',
        children: [
            {
                path: '',
                redirectTo: 'day-pick',
                pathMatch: 'full'
            },
            {
                path: 'day-pick',
                loadChildren: () => import('./pages/booking/day-pick/day-pick.module').then((m) => m.DayPickPageModule)
            },
            {
                path: 'time-slot-pick',
                loadChildren: () =>
                    import('./pages/booking/time-slot-pick/time-slot-pick.module').then((m) => m.TimeSlotPickPageModule)
            },
            {
                path: 'spot-pick',
                loadChildren: () => import('./pages/booking/spot-pick/spot-pick.module').then((m) => m.SpotPickPageModule)
            },
            {
                path: 'confirm',
                loadChildren: () => import('./pages/booking/confirm/confirm.module').then((m) => m.ConfirmPageModule)
            },
            {
                path: 'checkout',
                loadChildren: () => import('./pages/booking/booking-checkout/booking-checkout.module').then(m => m.BookingCheckoutPageModule)
            }
        ]
    },
    {
        path: 'booking-transit',
        children: [
            {
                path: '',
                redirectTo: 'booking-transit-page',
                pathMatch: 'full'
            },
            {
                path: 'booking-transit-page',
                loadChildren: () => import('./pages/booking/booking-transit/booking-transit.module').then((m) => m.BookingTransitPageModule)
            },
            {
                path: 'day-time-pick',
                loadChildren: () => import('./pages/booking/day-time-pick/day-time-pick.module').then((m) => m.DayTimePickPageModule)
            },
            
            {
                path: 'search-destinations',
                loadChildren: () =>
                    import('./pages/booking/search-destinations/search-destinations.module').then((m) => m.SearchDestinationsPageModule)
            },
            {
                path: 'results',
                loadChildren: () => import('./pages/booking/booking-transit/booking-transit-results/booking-transit-results.module').then((m) => m.BookingTransitResultsPageModule)
            },
            {
                path: 'confirm',
                loadChildren: () => import('./pages/booking/booking-transit/confirm/booking-transit-confirm-routing.module').then((m) => m.BookingTransitConfirmPageRoutingModule)
            },
            {
                path: 'details',
                loadChildren: () => import('./pages/booking/booking-transit/booking-details/booking-details-routing.module').then((m) => m.BookingDetailsPageRoutingModule)
            },
            {
                path: 'details/:id',
                loadChildren: () => import('./pages/booking/booking-transit/booking-details/booking-details-routing.module').then((m) => m.BookingDetailsPageRoutingModule)
            },
            {
                path: 'business',
                loadChildren: () => import('./pages/booking/booking-transit/business-vehicles/business-vehicles-routing.module').then((m) => m.BusinessVehiclesPageRoutingModule)
            },
            {
                path: 'business/:id',
                loadChildren: () => import('./pages/booking/booking-transit/business-vehicles/business-vehicles-routing.module').then((m) => m.BusinessVehiclesPageRoutingModule)
            },
            {
                path: 'myBookingDetails/:id',
                loadChildren: () =>
                    import('./pages/my-orders-details/my-orders-details.module').then(
                        (m) => m.MyOrdersDetailsPageModule
                    )
            },
            
            {
                path: 'checkout',
                loadChildren: () => import('./pages/booking/booking-checkout/booking-checkout.module').then(m => m.BookingCheckoutPageModule)
            }
            
        ]
    },
    {
        path: 'wallet',
        loadChildren: () => import('./pages/wallet/wallet.module').then((m) => m.WalletPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'booking-history',
        loadChildren: () => import('./pages/booking-history/booking-history.module').then(m => m.BookingHistoryPageModule)
    },
    {
        path: 'booking-history/:id',
        loadChildren: () => import('./pages/booking-history-single-booking/booking-history-single-booking.module').then(m => m.BookingHistorySingleBookingPageModule)
    },
    {
        path: 'tableOptions',
        loadChildren: () =>
            import('./pages/table-options/table-options.module').then(
                (m) => m.TableOptionsPageModule
            )
    },
    {
        path: 'tableOptions/review',
        loadChildren: () =>
            import('./pages/table-options/table-options.module').then(
                (m) => m.TableOptionsPageModule
            )
    },
    {
        path: 'communities',
        loadChildren: () => import('./pages/communities/communities.module').then(m => m.CommunitiesPageModule)
    },
    {
        path: '**', // Wildcard route for a 404 page
        loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundPageModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
