import { Time } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MapPoint } from './map-point.interface';
import { DeliveryMethod } from "../pages/order-type/delivery-method-discount-label/delivery-method.enum";
import { fromZonedTime } from 'date-fns-tz';

export function objectsEqual(a: object, b: object) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function trackById<K = number, T extends { id: K } = { id: K }>(item: T): K {
  return item.id;
}

export function stringifiedTimeToTime(stringifiedTime: string): Time {
  const [hours, minutes] = stringifiedTime.split(':').map((val) => parseInt(val, 10));

  return { hours, minutes };
}

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function timezoneDateToLocal(date: Date, timezone: string): Date {
  return fromZonedTime(date, timezone);
}

export function mapDateToNgbDate(date: Date): NgbDateStruct {
  return (
    date && {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    }
  );
}

export function mapNgbDateToDate(date: NgbDateStruct): Date {
  if (!date) {
    return null;
  }

  const { year, month, day } = date;

  return new Date(year, month - 1, day);
}

export function getDistanceBetweenPoints(start: MapPoint, end: MapPoint, units: string): number {

  let earthRadius = {
      miles: 3958.8,
      km: 6371
  };

  let R = earthRadius[units || 'miles'];
  let lat1 = start.lat;
  let lon1 = start.lng;
  let lat2 = end.lat;
  let lon2 = end.lng;

  let dLat = (lat2 - lat1) * Math.PI / 180.0;
  let dLon = (lon2 - lon1) * Math.PI / 180.0;
  let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180.0) * Math.cos(lat2 * Math.PI / 180.0) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;

  return d;
}

function computeDiscount(discountPercent: number, price: number) {
  if (discountPercent && discountPercent > 0) {
    const discount = (price * discountPercent) / 100;
    return price - discount;
  } else {
    return price;
  }
}

export function computeDiscountedPrice(price: number, discountPercent: number, shop: any, selectedDeliveryMethod: any, menuCategories?: Array<any>, categoryId?: any): number {
  let deliveryDiscount = computeDeliveryDiscount(shop, selectedDeliveryMethod);
  let menuCategoryDiscount = 0;
  if(menuCategories && categoryId) {
    let categoryFound = menuCategories.find(nextCategory => nextCategory.id == categoryId);
    if(categoryFound) {
      menuCategoryDiscount = categoryFound.discountPercentage;
    }
  }
  let discountedPrice = computeDiscount(deliveryDiscount, computeDiscount(menuCategoryDiscount, computeDiscount(discountPercent, price)));
  if(discountedPrice == price) {
    return price;
  }
  return Math.trunc(discountedPrice * 100) / 100
}

function computeDeliveryDiscount(shop: any, selectedDeliveryMethod: string) {
  if(!shop || !selectedDeliveryMethod) {
    return 0;
  } else {
    let deliveryMethod = DeliveryMethod.allValues.find(nextDeliveryMethod => nextDeliveryMethod.type == selectedDeliveryMethod);
    if(shop[deliveryMethod.discountField]) {
      return shop[deliveryMethod.discountField];
    }
    return 0;
  }
}


