import { Observable, BehaviorSubject, of, throwError, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { map, catchError, take } from 'rxjs/operators';
import { takeWhile } from 'rxjs/operators';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { OrderRest } from '../../models/order-rest.interface';
import { Events } from '../events/events.service';
import { OrderSpeed } from 'src/app/models/order-speed.interface';
import { OrderSpeedRest } from 'src/app/models/order-speed-rest.interface';
import { UserDataService } from '../user-data/user-data.service';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Capacitor } from '@capacitor/core';
import { OrderPromo, Promo } from 'src/app/models/order-promo.interface';
import { TranslateService } from '@ngx-translate/core';
import { OrderStatus } from 'src/app/constants/common.constants';
import { datadogLogs } from '@datadog/browser-logs';
import { ModalController, NavController } from '@ionic/angular';
import { RejectedOrdersModalComponent } from '../../components/rejected-orders-modal/rejected-orders-modal.component';
import { BackgroundGeolocationService } from '../background-geolocation/background-geolocation.service';
import { MapLocation, MapPoint } from 'src/app/models/map-point.interface';
import { TravelTimeEstimation } from 'src/app/models/travel-time.interface';
import { getDistanceBetweenPoints } from 'src/app/models/utility-functions';
import { CafesService } from '../cafes/cafes.service';
import { GoogleMapsService } from '../google-maps/google-maps.service';
import { OrderType } from 'src/app/models/order-type.type';
import { ShopItem } from 'src/app/models/shop-item.interface';
import { Order } from 'src/app/models/order.interface';
import { LocaleService } from '../locale/locale.service';
import { getUnixTime, addSeconds, differenceInDays, fromUnixTime } from 'date-fns';

const TIMER_PERIOD_MS: number = 30000;

interface OrdersResponse {
  order: any[];
}

interface OrderEtaResponse {
  order: any
}

interface OrderResponse {
  order: any;
}

interface SpeedOrderResponse {
  speedorder: any;
}


@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private _orders: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  public readonly orders: Observable<Array<any>> = this._orders.asObservable();

  private _speedOrders: BehaviorSubject<Array<OrderSpeedRest>> = new BehaviorSubject([]);
  public readonly speedOrders: Observable<Array<OrderSpeedRest>> = this._speedOrders.asObservable();
  public loadingSpeedOrders: boolean = false;

  private _serverConnection: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly serverConnection: Observable<boolean> = this._serverConnection.asObservable();

  runTimer: boolean = false;
  taskTimer: any;
  
  readonly orderStatusTranslationsMap = new Map([
    [OrderStatus.all, 'HISTORY.STATUS_ALL'],
    [OrderStatus.pending, 'HISTORY.STATUS_PENDING'],
    [OrderStatus.inprogress, 'HISTORY.STATUS_INPROGRESS'],
    [OrderStatus.ready, 'HISTORY.STATUS_READY'],
    [OrderStatus.queued, 'HISTORY.STATUS_QUEUED'],
    [OrderStatus.delayed, 'HISTORY.STATUS_DELAYED'],
    [OrderStatus.rejected, 'HISTORY.STATUS_REJECTED'],
    [OrderStatus.canceled, 'HISTORY.STATUS_CANCELED'],
    [OrderStatus.delivered, 'HISTORY.STATUS_DELIVERED'],
    [OrderStatus.disputed, 'HISTORY.STATUS_DISPUTED'],
    [OrderStatus.ontheway, 'HISTORY.STATUS_ONTHEWAY'],
    [OrderStatus.submitted, 'HISTORY.STATUS_SUBMITTED']
  ]);

  constructor(
    private http: HttpClient,
    private events: Events,
    private bgService: BackgroundGeolocationService,
    private userDataService: UserDataService,
    private cafesService: CafesService,
    private googleMapsService: GoogleMapsService,
    private translateService: TranslateService,
    private logger: NGXLogger,
    private badge: Badge,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private localeService: LocaleService
  ) {

    this.userDataService.userToken$.subscribe(token => {
      if (token) {
        if (!this.runTimer) {
          this.runTimer = !this.runTimer;
  
          timer(0, TIMER_PERIOD_MS).pipe(
            takeWhile(() => this.runTimer)).subscribe(() => {
              this.timerTick();
            });
        }
      }
      else {
        this._orders.next([]);
        this._speedOrders.next([]);
        
        if (this.runTimer) {
          this.runTimer = !this.runTimer;
          // clearInterval(this.taskTimer);
        }
        
        if (Capacitor.isNativePlatform()) {
          this.badge.clear();
        }
      }
    });

    this.orders.subscribe(orders => {
      let dtOrders = orders.filter(ord => (ord.type === 'drivethru' && ord.typeData?.isRT === 'true')).length;
      if (dtOrders > 0) {
        this.bgService.startTracking();
      }
      else {
        this.bgService.stopTracking();
      }
    });

    this.bgService.watchUserLocation$.subscribe(loc => {
      this.onBackgroundLocation(loc);
    });

  }

  async onBackgroundLocation(loc: MapLocation) {

    let token = this.userDataService.getUserToken();
    if (token) {
      
      let orders = this.getCachedOpenOrders();
      
      for (let ord of orders) {
        if (ord.type === 'drivethru' && ord.typeData.isRT === 'true') {
          try {
            let cafe = await this.cafesService.getCafeDetails(ord.shopId).toPromise();
            let cafeLoc: MapPoint = {lat: parseFloat(cafe.latitude), lng: parseFloat(cafe.longitude)};
             
            try {
              let travelTime: TravelTimeEstimation = await this.calculateTravelTime({lat: loc.position.lat, lng: loc.position.lng}, cafeLoc, ord.typeData.travelMode);
            
              if (travelTime.eta > 0) {
                let eta = getUnixTime(addSeconds(new Date(), travelTime.eta));

                let headers = new HttpHeaders();
                headers = headers.append('Authorization', 'Bearer ' + token);
            
                let url_params: HttpParams = new HttpParams();
                url_params = url_params.append('id', ord.id);
                url_params = url_params.append('eta', eta.toString());

                try {
                  let res = await this.http.get<OrderEtaResponse>(environment.QpassConfiguration.apiUrl + '/order/eta', {headers: headers, params: url_params}).toPromise();
                  datadogLogs.logger.info(`ETA has been successfully updated for order with ID ${res.order.id}. Updated ETA=${res.order.eta}.`, {travelTime: travelTime});
                }
                catch(apiErr) {
                  datadogLogs.logger.error(`Could not update ETA (${eta}) for order with ID ${ord.id}.`, {error: apiErr, travelTime: travelTime});
                }
              }
              else {
                datadogLogs.logger.error(`ETA calculation error (0) for order with ID ${ord.id}.`, {travelTime: travelTime});
              }
            }
            catch(err) {
              datadogLogs.logger.error(`ETA calculation error (exception) for order with ID ${ord.id}.`, {error: err});
            }
          }
          catch(err) {
            datadogLogs.logger.error(`Could not retrieve details for shop with ${ord.shopId}.`, {error: err});
          }
        }
      }
    }
    else {
      datadogLogs.logger.error('Location retrieved but no user token has been defined.');
    }

  }

  private calculateTravelTime(origin: MapPoint, destination: MapPoint, mode: string): Promise<TravelTimeEstimation> {
    
    let distanceInKm = getDistanceBetweenPoints(origin, destination, 'km');
    if (distanceInKm > 10) {
      return new Promise((resolve, reject) => {
        let movingSpeed: number = 5.0; // speed in km/h
        if (mode === 'drive') {
          movingSpeed = 70.0;
        }
        else if (mode === 'walk') {
          movingSpeed = 4.5;
        }
        else if (mode === 'bicycle') {
          movingSpeed = 10.0;
        }
        let calc_eta: number = (distanceInKm / movingSpeed) * 3600;
        let res: TravelTimeEstimation = { provider: 'buildin_straight', eta: calc_eta, status: "OK", origin: origin, destination: destination};
        resolve(res);
      });
    }
    else {
      return this.googleMapsService.getTravelTime(origin, destination, mode);
    }

  }

  getActiveOrders() {
    let token = this.userDataService.getUserToken();
    if (token) {
      this.getOpenOrders().subscribe(orders => {
        this._serverConnection.next(true);
        
        this._orders.next(orders);
        if (Capacitor.isNativePlatform()) {
          this.badge.set(orders.length);
        }
      }, err => {
        console.error(err);
        this._serverConnection.next(false);
      });
    }
  }

  updateActiveOrder(order) {
    let bFound: boolean = false;
    let orders = this._orders.getValue();
    let idx = 0;
    
    this.events.publish('user:orderUpdated', {order: order});
    
    for (let ord of orders) {
      if (ord.id === order.id) {
        bFound = true;
        break;
      }
      idx++;
    }

    if (bFound) {
      Object.assign(orders[idx], order);  //merge objects **to keep user data
      order = orders[idx];
      orders.splice(idx, 1);
      if (order.status === 'pending' || order.status === 'queued' || order.status === 'delayed' || order.status === 'inprogress' || order.status === 'ready' || order.status === 'ontheway') {
        orders.unshift(order);
      }
      this._orders.next(orders);
      if (Capacitor.isNativePlatform()) {
        this.badge.set(orders.length);
      }
    }
    else {
      let token = this.userDataService.getUserToken();
      if (token) {

        let orderRequest = this.getOrderDetails(order.id);
        if ('iat' in order) { // got all information and not need to fetch order details
          orderRequest = of(order);
        }

        orderRequest.subscribe(myorder => {
          orders = this._orders.getValue(); //get last value
          bFound = false;
          idx = 0;

          for (let ord of orders) { //check again to exclude list updated while waiting for orders details
      
            if (ord.id === myorder.id) {
              bFound = true;
              break;
            }
            idx++;
          }

          if (bFound) {
            Object.assign(orders[idx], myorder);  //merge objects **to keep user data
            myorder = orders[idx];
            orders.splice(idx, 1);
          }
          if (myorder.status === 'pending' || myorder.status === 'queued' || myorder.status === 'delayed' || myorder.status === 'inprogress' || myorder.status === 'ready' || myorder.status === 'ontheway') {
            orders.unshift(myorder);
          }
          this._orders.next(orders);
          if (Capacitor.isNativePlatform()) {
            this.badge.set(orders.length);
          }
        }, err => {
          console.error(err);
        });
      }
    }
  }

  getCachedOpenOrders(): Array<any> {
    return this._orders.getValue();
  }

  getOpenOrders(pageIndex: number = -1, pageCount?: number, filterDate?: any): Observable<Array<any>> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      if (pageIndex >= 0) url_params = url_params.append('page', pageIndex.toString());
      if (pageCount >= 0) url_params = url_params.append('count', pageCount.toString());
      if (filterDate) {
        if (filterDate.dateFrom > 0) url_params = url_params.append('dateFrom', filterDate.dateFrom);
        if (filterDate.dateTo > 0 && filterDate.dateTo >= filterDate.dateFrom) url_params = url_params.append('dateTo', filterDate.dateTo);
      }
      
      return this.http.get<OrdersResponse>(environment.QpassConfiguration.apiUrl + '/order/list/open', {headers: headers, params: url_params}).pipe(
        map(res => res.order),
        catchError(this.handleError('providers.orders.getOpenOrders'))
      );
    }
    else {

    }
  }

  getFinalizedOrders(pageIndex: number = -1, pageCount?: number, filterDate?: any): Observable<Array<any>> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      if (pageIndex >= 0) url_params = url_params.append('page', pageIndex.toString());
      if (pageCount >= 0) url_params = url_params.append('count', pageCount.toString());
      if (filterDate) {
        if (filterDate.dateFrom > 0) url_params = url_params.append('dateFrom', filterDate.dateFrom);
        if (filterDate.dateTo > 0 && filterDate.dateTo >= filterDate.dateFrom) url_params = url_params.append('dateTo', filterDate.dateTo);
      }
      return this.http.get<OrdersResponse>(environment.QpassConfiguration.apiUrl + '/order/list/finalized', {headers: headers, params: url_params}).pipe(
        map(res => res.order),
        catchError(this.handleError('providers.orders.getFinalizedOrders'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  getOrders(pageIndex: number = -1, pageCount?: number, filterStatus?: string, filterDate?: any): Observable<Array<OrderRest>> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      if (pageIndex >= 0) url_params = url_params.append('page', pageIndex.toString());
      if (pageCount >= 0) url_params = url_params.append('count', pageCount.toString());
      if (filterStatus && (filterStatus !== 'all')) {
        url_params = url_params.append('status', filterStatus);
      }
      if (filterDate) {
        if (filterDate.dateFrom > 0) url_params = url_params.append('dateFrom', filterDate.dateFrom);
        if (filterDate.dateTo > 0 && filterDate.dateTo >= filterDate.dateFrom) url_params = url_params.append('dateTo', filterDate.dateTo);
      }
      return this.http.get<OrdersResponse>(environment.QpassConfiguration.apiUrl + '/order/list', {headers: headers, params: url_params}).pipe(
        map(res => res.order),
        catchError(this.handleError('providers.orders.getOrders'))
      );
    }
    else {
      return of([]);
    }
  }

  getOrderDetails(orderID): Observable<any> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('id', orderID);
      url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));

      return this.http.get<OrderResponse>(environment.QpassConfiguration.apiUrl + '/order/get', {headers: headers, params: url_params}).pipe(
        map(res => res.order),
        catchError(this.handleError('providers.orders.getOrderDetails'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  getOrderPromos(order: Order): Observable<OrderPromo> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Authorization', 'Bearer ' + token);

      let body = order;
      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('locale', this.localeService.getLanguageCountry(this.localeService.getSelectedLanguage()));

      return this.http.post<OrderPromo>(environment.QpassConfiguration.apiUrl + '/order/promos', body, {headers: headers, params: url_params}).pipe(
        catchError(this.handleError('providers.orders.getOrderPromos'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  createPromoList(orderPromo: OrderPromo): Array<Promo> {
    let lstPromos: Array<Promo> = [];
    for (let cp of orderPromo.coupons) {
      let prm: Promo = {
        id: `c_${cp.id}`,
        isCoupon: true,
        title: cp.offer.title,
        description: cp.offer.description,
        message: cp.message,
        couponName: cp.name,
        gift: cp.gift,
        discount: cp.discount,
        conditions: cp.offer.conditions
      };
      lstPromos.push(prm);
    }

    orderPromo.offers.filter(offr => offr.message != "not in beneficiaries").forEach(offr => {
      let prm: Promo = {
        id: `o_${offr.id}`,
        isCoupon: false,
        title: offr.title,
        description: offr.description,
        message: offr.message,
        gift: offr.gift,
        discount: offr.discount,
        conditions: offr.conditions
      };
      lstPromos.push(prm);
    });

    return lstPromos;
  }

  setOrderStatus(orderID, status, disputedReason?: string): Observable<any> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('id', orderID);
      url_params = url_params.append('status', status);

      if (disputedReason) {
        url_params = url_params.append('reason', disputedReason);
      }

      return this.http.get<OrderResponse>(environment.QpassConfiguration.apiUrl + '/order/status/set', {headers: headers, params: url_params}).pipe(
        map(res => res.order),
        catchError(this.handleError('providers.orders.setOrderStatus'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  placeOrder(order: any): Promise<any> {

    return new Promise((resolve, reject) => {
      let userToken = this.userDataService.getUserToken();
      if (userToken) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', 'Bearer ' + userToken);

        let body = order;

        this.logger.info('User placed new order', { url: environment.QpassConfiguration.apiUrl + '/order/new', headers: headers, body: body });
        this.http.post<OrderResponse>(environment.QpassConfiguration.apiUrl + '/order/new', body, {headers: headers}).pipe(
          map(res => res.order)
        ).subscribe(newOrder => {
          // let orders = this._orders.getValue();
          // orders.unshift(newOrder);
          // this._orders.next(orders);
          this.updateActiveOrder(newOrder);

          resolve({resultCode: 0, resultDescription: 'PLACE_ORDER_SRV.SUCCESS', data: newOrder});
        }, err => {
          this.logger.info('Placing new order failed', { result: err });
          reject({resultCode: 1, resultDescription: 'PLACE_ORDER_SRV.ERR'});
        });
      }
      else {
        reject({resultCode: 1, resultDescription: 'PLACE_ORDER_SRV.ERR'});
      }
    });
  }

  isValidForReview(orderIat, orderStatus): boolean {
    return (((orderStatus === 'delivered') || (orderStatus === 'disputed') || (orderStatus === 'rejected') || (orderStatus === 'canceled')) && (differenceInDays(new Date(), fromUnixTime(orderIat)) <= 30));
  }

  isOrderTypeValid(orderType: OrderType, shopDetails: ShopItem): boolean {
    let output: boolean;

    const { delivery, driveThrough, takeAway, qtableService } = shopDetails;

    switch (orderType) {
      case 'takeaway': {
        output = takeAway;
        break;
      }
      case 'delivery': {
        output = delivery;
        break;
      }
      case 'drivethru': {
        output = driveThrough;
        break;
      }
      case 'qtable': {
        output = qtableService;
        break;
      }
    }

    return !!output;
  }

  performAddSpeedOrder(order: OrderSpeed): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.addSpeedOrder(order, false).subscribe(newSpeedOrder => {
        let spOrders = this._speedOrders.getValue();
        spOrders.push(newSpeedOrder);
        this._speedOrders.next(spOrders);
        resolve(true);
      }, err => {
        reject(false);
      })
    });
  }

  addSpeedOrder(order: OrderSpeed, preview: boolean) {
    let token = this.userDataService.getUserToken();
    if (token) {
      let url_params: HttpParams = new HttpParams();
      if (preview) url_params = url_params.append('preview', 'true');

      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Authorization', 'Bearer ' + token);

      let body = order; //{...order, ...(preview && {preview: true})};

      return this.http.post<SpeedOrderResponse>(environment.QpassConfiguration.apiUrl + '/speedorder/new', body, {headers: headers, params: url_params}).pipe(
        map(res => res.speedorder),
        catchError(this.handleError('providers.orders.addSpeedOrder'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  performRemoveSpeedOrder(orderId): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.removeSpeedOrder(orderId).subscribe(res => {
        if (res === "removed") {
          let spOrders = this._speedOrders.getValue();
          for (let idx = spOrders.length - 1; idx >= 0; --idx) {
            if (spOrders[idx].id === orderId) {
              spOrders.splice(idx, 1);
            }
          }
          this._speedOrders.next(spOrders);
        }
        resolve(res === "removed");
      }, err => {
        reject(false);
      })
    });
  }
  

  removeSpeedOrder(orderId) {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers = headers.append('Authorization', 'Bearer ' + token);

      let body = "id=" + orderId;
      
      return this.http.post<SpeedOrderResponse>(environment.QpassConfiguration.apiUrl + '/speedorder/remove', body, {headers: headers}).pipe(
        map(res => res.speedorder),
        catchError(this.handleError('providers.orders.removeSpeedOrder'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  populateSpeedOrders() {
    let token = this.userDataService.getUserToken();
    if (token) {
      this.loadingSpeedOrders = true;
      this.getSpeedOrders().subscribe(so => {
        this._speedOrders.next(so);
      }, err => {
        console.error(err);
      }, () => {
        this.loadingSpeedOrders = false;
      });
    }
  }

  getSpeedOrders(): Observable<Array<any>> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      return this.http.get<Array<any>>(environment.QpassConfiguration.apiUrl + '/speedorder/list', {headers: headers}).pipe(
        // map(res => res.order),
        catchError(this.handleError('providers.orders.getSpeedOrders'))
      );
    }
    else {
      return of([]);
    }
  }

  getSpeedOrder(orderId): Observable<OrderSpeed> {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + token);

      let url_params: HttpParams = new HttpParams();
      url_params = url_params.append('id', orderId);

      return this.http.get<OrderSpeed>(environment.QpassConfiguration.apiUrl + '/speedorder/get', {headers: headers, params: url_params}).pipe(
        map(res => res),
        catchError(this.handleError('providers.orders.getSpeedOrder'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  getSpeedOrdersNames(): Array<string> {
    let arr = [];
    for (let so of this._speedOrders.getValue()) {
      arr.push(so.name);
    }
    return arr;
  }

  rejectedModals = [];
  async showRejectedModal(order, showGoToOrder = true): Promise<boolean> {
    return new Promise(async( resolve, reject) => {

      if (this.rejectedModals.length === 0 || !(this.rejectedModals.some((m) => m.componentProps.orderInfo.id === order.id))) {

        const cssClasses = ['info-modal', 'info-modal--top'];

        if (!order.rejectionReason) {
          cssClasses.push('info-modal--height_220');
        }

        const modalObj = {
          component: RejectedOrdersModalComponent,
          componentProps: {
            orderInfo: order,
            showGoToOrder: showGoToOrder
          },
            cssClass: cssClasses
        };

        this.rejectedModals.push(modalObj);
        const modal = await this.modalCtrl.create(modalObj);
    
        modal.onWillDismiss().then((res: any) => {
          this.setRejectionReasonRead(order.id).subscribe((result) => {
            this.updateActiveOrder(result.order);

            if (res.data && res.data.orderId && res.data.orderId > 0) {
              this.navCtrl.navigateRoot(`/myOrdersDetails/${res.data.orderId}`);
            }
  
            const modalIndex = this.rejectedModals.findIndex((m) => m.componentProps.orderInfo.id === result.order.id);
      
            if (modalIndex !== -1) {
              this.rejectedModals.splice(modalIndex, 1)
            }
    
            resolve(true)
          });
        });
    
        await modal.present();
      }
    });
  }

  setRejectionReasonRead(orderId) {
    let token = this.userDataService.getUserToken();
    if (token) {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers = headers.append('Authorization', 'Bearer ' + token);

      let body = "id=" + orderId;

      return this.http.post<any>(environment.QpassConfiguration.apiUrl + '/order/rejectionReasonRead',body,{headers: headers}).pipe(
        catchError(this.handleError('providers.orders.setRejectionReasonRead'))
      );
    }
    else {
      return throwError(() => new Error('USER_SRV.ERR'));
    }
  }

  private handleError (operation = 'operation') {
    return (err: HttpErrorResponse) => {
      let errMsg: string = "USER_SRV.ERR";
      
      if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        this.logger.log(`${operation} failed: ${err.error.message}`);
        errMsg = "USER_SRV.ERR_NO_WEB_ACCESS";
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        this.logger.log(`${operation} failed: Backend returned code ${err.status}, body was: ${err.error}`);
        errMsg = "USER_SRV.ERR_SERVER_COMM";
      }
      return throwError(errMsg);
    }
  }

  private timerTick() {
    this.getActiveOrders();
  }

}
